/*
 * Globals
 */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

@font-face {
  font-family: nickit;
  src: url(assets/font/timeburner.ttf) format("opentype");
}

a {
  color: #6c757d;
  text-decoration: underline;
}

a:hover {
  color: #4a4b4e;
}

@media (min-width: 768px) {
.container-sm {
    max-width: 60%;
  }
}

@media (min-width: 1200px) {
.container-sm {
    max-width: 40%;
  }
}